<template>
    <v-menu
        bottom
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" depressed :color="color">
                <v-icon>mdi-apps</v-icon>
            </v-btn>
        </template>
                                    
        <v-list light>
            <v-list-item>
                <v-list-item-title class="text_style">Switch to</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="(app, i) in account.apps" :key=i :href = "$store.getters[app.code +'_url']">
                <v-list-item-avatar class="image-position"><v-img v-bind:src="require('@/assets/Icons/'+app.code+'.png')" max-height="20" max-width="20"></v-img></v-list-item-avatar>
                <v-list-item-title v-text="app.name" ></v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>  
</template>

<script>
export default {
    name: 'AppMenu',
    props: {
        color: String
    },
    computed: {
        account () {            
            return this.$store.state.login.account
        }
    }
}
</script>

<style>
    .text_style {
        font-weight: bold;
    }

    .image-positon {
        margin: 0;
    }

    .v-application--is-ltr .v-list-item__avatar:first-child {
        margin: 0;
    }
</style>
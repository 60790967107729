<template>
    <v-main class="testClass">
        <v-container class="fill-height" fluid>
            <div class="floatingButton">
                <AppMenu color="white" />
            </div>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="5" class="text-center">
                    <h2 class="white--text">Nedozvoljen pristup</h2>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import AppMenu from '@/components/App/AppMenu.vue'

export default {
    components: {
        AppMenu
    },
    created() {
        this.$store.dispatch('setLoader', false)

        if (this.$store.getters.sso_token == '') this.$router.push('/')
    },
    computed: {}
}
</script>

<style scoped>
.testClass {
    background: #446e9b;
}

.testClass p {
    color: #fff;
}

.floatingButton {
    position: absolute;
    top: 10px;
    left: 10px;
}
</style>
